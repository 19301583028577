<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";

  interface AuthenticationRequestResponse {
    token: string;
    verificationCode: string;
  }

  interface AuthenticationResponse {
    token: string;
  }

  export let provider = 'mobileid';

  let mobileNumber: string = '';
  let personalCode: string = '';
  let verificationCode: string | null = null;

  let abortController: AbortController = new AbortController();
  const dispatch = createEventDispatcher();

  // If component is destroyed, abort all fetch requests
  onDestroy(() => abortController.abort());
  // If provider is changed between mID and sID, reset this component
  $: provider, resetComponent();

  function resetComponent() {
    // Cancel any ongoing requests
    abortController.abort();
    // Reset component
    verificationCode = null;
  }

  function handleError(text: string, error: any = null) {
    // Reset component
    verificationCode = null;
    // Don't show an error message when requests were cancelled with user actions
    if (error && 'name' in error && error.name === 'AbortError') {
      return;
    }
    dispatch('message', {'text': text});
  }

  function clearError() {
    dispatch('clearMessage');
  }

  function validate() {
    // Clean up any whitespace
    personalCode = personalCode.replace(/\s/g, '');
    mobileNumber = mobileNumber.replace(/\s/g, '');

    if (!personalCode.match(/^[0-9]{11}$/)) {
      handleError("Sisestatud isikukood on vigane");
      return false;
    }
    if (provider === 'mobileid') {
      if (!mobileNumber.match('^\\+?\\s*[0-9\\s]{5,30}')) {
        handleError("Sisestatud mobiilinumber on vigane")
        return false
      }
    }
    return true;
  }

  function startAuthentication() {
    if (!validate()) {
      return;
    }

    abortController = new AbortController();
    clearError();

    let reqBody;
    if (provider === 'smartid') {
      reqBody = {
        nationalIdentityNumber: personalCode
      }
    } else if (provider === 'mobileid') {
      reqBody = {
        nationalIdentityNumber: personalCode,
        phoneNumber: mobileNumber
      }
    }

    fetch(urls[provider] + '/authenticationRequest', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(reqBody),
      signal: abortController.signal
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error("/authenticationRequest API call failed")
      }
    }).then(authenticate)
    .catch(reason => {
      handleError('Autentimislahenduse töös esineb hetkel tõrkeid. Palun proovige mõne aja pärast uuesti', reason);
    });
  }

  function authenticate(authReqResponse: AuthenticationRequestResponse) {
    const authUrl = urls[provider] + '/authenticate';
    verificationCode = authReqResponse.verificationCode;
    fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-authentication-session': authReqResponse.token
      },
      signal: abortController.signal
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error("/authenticate API call failed")
      }
    }).then(startSession)
    .catch(reason => {
      handleError('Kontrollige, et sisestasite korrektsed andmed ning tegutsesite vastavalt juhistele telefoni ekraanil', reason);
    });
  }

  function startSession(authResponse: AuthenticationResponse) {
    fetch('/validate-login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        resultToken: authResponse.token,
        method: provider
      }),
      signal: abortController.signal
    }).then(response => {
      if (response.ok) {
        window.location.href = '/delegate/authorization';
      } else {
        throw Error("/validate-login API call failed");
      }
    }).catch(reason => {
      handleError('Patsiendiportaali töös esineb hetkel tõrkeid. Palun proovige mõne aja pärast uuesti', reason);
    })
  }

  function cancelAuthentication() {
    abortController.abort();
  }
</script>

<div id="provider-container">
    {#if verificationCode === null}
    <div class="login-form__input">
        <label for="personalCode">Isikukood</label>
        <input type="text" class="v-textfield v-widget" id="personalCode" maxlength="15" bind:value={personalCode}>
    </div>
    {#if provider === 'mobileid'}
        <div class="login-form__input">
            <label for="mobileNumber">Mobiilinumber</label>
            <input type="text" class="v-textfield v-widget" id="mobileNumber" maxlength="20" bind:value={mobileNumber}>
        </div>
    {/if}
    <div tabindex="0" role="button"
         class="v-button v-widget button-small v-button-button-small button-main v-button-button-main button-forward v-button-button-forward"
         on:click={startAuthentication}
    >
        <span class="v-button-wrap"><span class="v-button-caption">Jätka</span></span>
    </div>
    {:else}
    <p>Kontrollkood: <strong>{verificationCode}</strong></p>
    <p>Palun kontrollige, et näete mobiiltelefoni ekraanil täpselt sama koodi. Seejärel järgige juhiseid telefoni ekraanilt.</p>
    <p><a href="#cancel" on:click={cancelAuthentication}>Katkesta</a></p>
    {/if}
</div>
