<script lang="ts">
  export const provider = 'idcard';

  function login() {
    window.location.href = urls.idcard;
  }
</script>

<div id="provider-container">
    <p>Veenduge, et ID-kaart oleks enne jätkamist korrektselt lugejas.</p>
    <div tabindex="0" role="button"
         class="v-button v-widget button-small v-button-button-small button-main v-button-button-main button-forward v-button-button-forward"
         on:click={login}>
        <span class="v-button-wrap"><span class="v-button-caption">Jätka</span></span>
    </div>
</div>

<style>
    #provider-container p {
        margin-top: 0;
        margin-bottom: 1em;
    }

    #provider-container .v-button {
        margin-top: 0.5em !important;
        margin-left: 0 !important;
    }
</style>
