<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from 'svelte/transition';

  import IDCard from "./IDCard.svelte";
  import MobileAndSmartID from "./MobileAndSmartID.svelte";

  const dispatch = createEventDispatcher();

  interface Provider {
    identifier: string;
    text: string;
    component: any
  }

  interface NotificationMessage {
    title?: string;
    text: string;
    class?: string;
  }

  const providers: Provider[] = [
    { identifier: 'idcard', text: 'ID-kaart', component: IDCard },
    { identifier: 'mobileid', text: 'Mobiil-ID', component: MobileAndSmartID },
    { identifier: 'smartid', text: 'SmartID', component: MobileAndSmartID }
  ];

  let activeProvider: Provider = null;

  let message: NotificationMessage;

  function handleMessageEvent(event: CustomEvent) {
    handleMessage(event.detail);
  }

  function handleMessage(newMessage: NotificationMessage) {
    if (!newMessage.title) {
      newMessage.title = 'Autentimine ebaõnnestus';
    }
    if (!newMessage.class) {
      newMessage.class = 'error';
    }
    message = newMessage;
  }

  function clearMessage() {
    message = undefined;
  }

  $: activeProvider, clearMessage();

  // TODO: how to get these texts?
  onMount(async () => {
    switch (window.location.hash) {
      case '#idcard-login-error':
        handleMessage({
          text: 'Veenduge, et ID-kaart on lugejas ja toimib. Täiendavat infot leiate lehelt ' +
            '<a href="https://www.id.ee/id-abikeskus/" target="_blank">id.ee</a>.'
        });
        break;
      case '#session-timeout':
        handleMessage({
          title: texts.session_timeout_title,
          text: texts.session_timeout_text
        });
        break;
      case '#logout':
        handleMessage({
          title: texts.logout_title,
          text: texts.logout_text,
          class: 'info'
        });
        break;
      default:
        return;
    }
  });
</script>

{#if message}
    <div id="page-message" class="{ message.class }" transition:fade>
        <p class="close"><a href="#closeMessage" on:click|preventDefault={clearMessage}>peida see teade</a></p>
        <h1>{ message.title }</h1>
        <p>{@html message.text }</p>
    </div>
{/if}

<div id="auth-container">
    <div id="auth-provider-list" class:horizontal="{activeProvider === null}">
        {#each providers as provider}
            <button class="auth-box" class:active={activeProvider===provider} on:click={() => activeProvider=provider}>
                <img src="/images/buttons/{provider.identifier}.svg" alt="{provider.text}">
            </button>
        {/each}
    </div>
    {#if (activeProvider !== null)}
        <div id="auth-provider" class="auth-box">
            <svelte:component
                this="{ activeProvider.component }"
                provider="{ activeProvider.identifier }"
                on:message={handleMessageEvent}
                on:clearMessage={clearMessage}
            />
        </div>
    {/if}
</div>

<style>
    .auth-box {
        background: white;
        border: 1px solid #DADFE1;
        box-sizing: border-box;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
    }

    #auth-container {
        display: flex;
        justify-items: center;
        flex-direction: row;
        align-items: center;
        padding: 2em;
    }

    #auth-provider-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 200px;
        margin-left: 120px;
    }

    #auth-provider-list.horizontal {
        flex-direction: row;
        justify-content: space-evenly;

        width: 100%;
        margin-left: 0;
        padding: 0 10rem;
    }

    #auth-provider-list button {
        width: 150px;
        height: 55px;
        margin: 1em;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        box-shadow: 0 0 2px #cecece;
    }

    #auth-provider-list button.active {
        filter: drop-shadow(0px 1px 4px #BBBBBB)
    }

    #auth-provider-list button img {
        width: 100px;
    }

    #auth-provider {
        width: 400px;
        min-height: 150px;
        margin-left: 30px;
        padding: 2em;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    #page-message {
        margin: 0;
    }

    :global(.itk #page-message) {
        padding: 20px 30px 25px !important;
        border: none !important;
        width: auto !important;
    }

    #page-message h1 {
        margin-bottom: 0.5rem;
    }

    :global(#provider-container) {
        width: 100%;
    }

    :global(.login-form__input) {
        justify-content: flex-start;
    }

    :global(label) {
        margin-right: 0.5em;
        width: 100px;
        text-align: right;
    }

    :global(#provider-container .v-button) {
        margin-top: 1.5em !important;
    }

    :global(#provider-container .button-main) {
        margin-left: 107px !important;
    }

    :global(.itk #provider-container .v-button-button-main) {
        height: 32px !important;
        line-height: 30px !important;
        font-size: 15px !important;
        text-transform: none !important;
        border-top: solid 1px #68b927;
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    }
</style>
